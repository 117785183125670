"use client";

import React from "react";
import styles from "./TextInput.module.scss";
import clsx from "clsx";
import { Eye, EyeOff } from "react-feather";

interface Props {
  name?: string;
  label?: string;
  required?: boolean;
  autoComplete?: string;
  placeholder?: string;
  type?: string;
  className?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  value?: string;
  theme?: "light" | "dark";
}

/**
 *
 * TextInput
 *
 */
const TextInput = ({
  name = "",
  label = "",
  required = false,
  autoComplete = null,
  theme = "light",
  placeholder = "",
  type = "text",
  className = "",
  onChange = () => {},
  onBlur = () => {},
  value = "",
  ...props
}: Props) => {
  const [showPassword, setShowPassword] = React.useState(false);
  return (
    <div className={styles["text-input"]} {...props}>
      <label
        htmlFor={name}
        className={`block text-body ${
          theme === "light" ? "text-gray-600" : "text-white"
        } uppercase`}
      >
        {label}
      </label>
      <div className="relative">
        <input
          value={value}
          id={name}
          name={name}
          type={type !== "password" ? type : showPassword ? "text" : "password"}
          placeholder={placeholder}
          autoComplete={autoComplete}
          required={required}
          className={clsx(
            `${className} mt-1 block w-full appearance-none border border-black px-3 h-12 placeholder-gray-400 shadow-sm focus:border-black focus:outline-none focus:ring-black sm:text-body rounded-think-sm`
          )}
          onChange={onChange}
          onBlur={onBlur}
          {...props}
        />
        {type === "password" && (
          <button
            type="button"
            className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-500"
            onClick={() => setShowPassword(!showPassword)}
          >
            <span className="sr-only">Show password</span>
            {showPassword ? (
              <EyeOff className="w-5 h-5" />
            ) : (
              <Eye className="w-5 h-5" />
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default TextInput;
