"use client";

import { useState } from "react";
import { signIn } from "next-auth/react";
import LoadingDots from "@/components/loading-dots";
import toast from "react-hot-toast";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { TextInput } from "@/components/form/TextInput";
import { Button } from "@/components/actions/Button";
import { useUser } from "@/hooks/useFullUser";
import { wait } from "@/lib/helpers";

export default function LoginForm({}: {}) {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const router = useRouter();

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault();
        setLoading(true);
        const result = await signIn("credentials", {
          email,
          password,
          redirect: false,
        });
        if (result?.error) {
          toast.error(result.error);
          setError("Error signing in");
          setLoading(false);
          return;
        }
        await wait(300);
        router.push("/app");
      }}
      className="grid gap-6 px-4 py-8 sm:px-16"
    >
      <TextInput
        name="email"
        type="email"
        placeholder="person@thinknado.com"
        autoComplete="email"
        required
        label="Email"
        value={email}
        onChange={(event) => setEmail(event.target.value)}
      />
      <TextInput
        name="password"
        type="password"
        placeholder="********"
        autoComplete="password"
        required
        label="Password"
        value={password}
        onChange={(event) => setPassword(event.target.value)}
      />

      {error && <p className="text-red-500 text-sm text-center">{error}</p>}

      <Button color="bg-yellow" disabled={loading} className="login-button">
        {loading ? <LoadingDots color="#808080" /> : <span>{"Sign In"}</span>}
      </Button>

      <p className="text-center text-sm text-black">
        Don&apos;t have an account?{" "}
        <Link href="/auth/sign-up" className="font-semibold text-purple">
          Create one now!
        </Link>
      </p>

      <p className="text-center text-sm text-black">
        <Link
          href={`/forgot-password?email=${email}`}
          className="font-semibold  text-purple"
        >
          Forgot your password?
        </Link>
      </p>
    </form>
  );
}
